import { createContext, useState, useEffect } from "react";
import { firebase } from "@project/shared";
import { useQuery } from "react-query";
import { fetchCompayDetails } from "../services/company";
import { GetCompanyID, getUserDetails } from "../services/user";

type Claims = {
  isCompany: boolean;
  isSupplier: boolean;
  isAdmin: boolean;
  isSbMember: boolean;
};

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  authenticated: any;
  setUser: any;
  claims: Claims;
  companyID: string;
  maxCompanyMember: number;
  companyUUID: string;
  companyName: string;
  unreadMessagesCount: number;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [loading, setLoading] = useState(true);
  const [claims, setClaims] = useState<Claims>({
    isCompany: false,
    isSupplier: false,
    isAdmin: false,
    isSbMember: false,
  });
  const [companyID, setCompanyID] = useState("");
  const [maxCompanyMember, setMaxCompanyMember] = useState(0);
  const [companyUUID, setCompanyUUID] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [authenticated, setAuthenticated] = useState(null as boolean | null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<
    number | undefined
  >(undefined);

  const { data: company } = useQuery(
    "getCompanyInformations",
    () => GetCompanyID(user?.uid),
    { refetchOnWindowFocus: false, enabled: user?.uid ? true : false }
  );
  const { data: companyInformation } = useQuery(
    "company-data",
    () => fetchCompayDetails(company?.data?.id),
    { refetchOnWindowFocus: false, enabled: company?.data?.id ? true : false }
  );
  const { data: userInfo } = useQuery(
    "fetch-header",
    () => getUserDetails(user?.uid),
    {
      refetchOnWindowFocus: true,
      enabled: user && user?.emailVerified ? true : false,
    }
  );
  useEffect(() => {
    setUnreadMessagesCount(userInfo?.unreadMessageCount || undefined);
  }, [userInfo]);
  useEffect(() => {
    initialLoad();
  }, []);
  useEffect(() => {
    if (company) {
      setCompanyID(company?.data?.id);
      setCompanyName(companyInformation?.data?.companyName);
      setMaxCompanyMember(companyInformation?.data?.maxMember);
    }
  }, [company, companyInformation]);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user!.getIdTokenResult();
        setClaims({
          isCompany: idToken.claims.isCompany,
          isSupplier: idToken.claims.isSupplier,
          isAdmin: idToken.claims.isAdmin,
          isSbMember: idToken.claims.isSbMember,
        });
      }

      setUser(user);
      setCompanyUUID(user?.uid);
      setAuthenticated(user !== null);
    });
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated,
        setUser,
        claims,
        companyID,
        companyName,
        maxCompanyMember,
        companyUUID,
        unreadMessagesCount,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
