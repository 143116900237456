import { API } from "@project/shared";

export const fetchCompayDetails = async (id) => {
  return API.get(`/companies/${id}`);
};

export const UpdateContractInformations = async ({
  formData,
  id,
}): Promise<any> => {
  return API.put(`/companies/${id}`, formData);
};
